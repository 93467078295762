import React from "react";
import "./footer.css";
import logo from "../../assests/img/logo.jpg";
const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-content ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info p-2 pt-5">
                  <h3>
                    Activated <span>Carbon</span>
                  </h3>

                  <p>
                    B2-200 Preston Pkwy, <br />
                    Cambridge,
                    <br />
                    ONN3H 5N1,Canada
                    <br />
                    <strong>Phone:</strong> +1(416)993-2955
                    <br />
                    {/* <strong>Email:</strong> info@example.com */}
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right" /> <a href="#">Home</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <a href="#">Product</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <a href="#">service</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Powdered Activated Carbon (PAC)</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Carbon Nanotubes (CNTs)</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Carbon Block Filters</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Extruded Activated Carbon</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Pelletized Activated Carbon</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal text-center">
          <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div className="copyright">
                © Copyright by{" "}
                <strong>
                  <span></span>
                </strong>
                The Activated Carbon
              </div>
              <div className="credits">
                <a>Designed by Virtual Technology</a>
              </div>
            </div>
            <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
              <a href="#" className="twitter">
                <i className="bi bi-twitter" />
              </a>
              <a href="#" className="facebook">
                <i className="bi bi-facebook" />
              </a>
              <a href="#" className="instagram">
                <i className="bi bi-instagram" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
};

export default Footer;
