import "./SocialMediaSideBar.css";
import whatsapp from "../../assests/img/whatsapp.png"
const SocialMediaSideBar = () => {
  return (
    <div className="sticky-icon">
      <a
        href="https://api.whatsapp.com/send?phone=+1 (416) 993-2955&amp;text=Welcome to THE ACTIVATED CARBON"
        target="_blank"
        rel="noreferrer"
      >
        <img src={whatsapp} alt="whatsapp"width={"40px"} height={"40px"}/>
      
      </a>
    </div>
  );
};

export default SocialMediaSideBar;
