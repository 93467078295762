import React from "react";
import "./about.css";
import feature1 from "../../assests/about/tab1.png";
import feature2 from "../../assests/carbonimg/16.jpg";
import feature3 from "../../assests/carbonimg/8.jpg";
import feature4 from "../../assests/service/carbon1.jpg";
import feature5 from "../../assests/carbonimg/ban imag.jpg";
import feature6 from "../../assests/about/img1.jpg";

import { Mission } from "./Mission";

const About = () => {
  return (
    <div id="aboutsession">
      <Mission />
      <section id="features" className="features">
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row gy-4 d-flex">
            <li className="nav-item col-6 col-md-4 col-lg-2 text-center">
              <a
                className="nav-link active show"
                data-bs-toggle="tab"
                data-bs-target="#tab-1"
              >
                <i className="fa-solid fa-bullseye  mb-4" />
                <h4>Our Mission</h4>
              </a>
            </li>
            {/* End Tab 1 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2 text-center">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-2"
              >
                <i className="bi bi-brightness-high " />
                <h4>Our Vision</h4>
              </a>
            </li>
            {/* End Tab 2 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2 text-center ">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-3"
              >
                <i className="fa-solid fa-star  mb-2" />

                <h4>Our Specialization</h4>
              </a>
            </li>
            {/* End Tab 3 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2 text-center">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-4"
              >
                <i className="bi bi-command " />
                <h4>Portfolio & Offering</h4>
              </a>
            </li>
            {/* End Tab 4 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2 text-center">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-5"
              >
                <i className="fa-brands fa-product-hunt  mb-2" />

                <h4>Package & Loading</h4>
              </a>
            </li>
            {/* End Tab 5 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2 text-center">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-6"
              >
                <i className="fa-solid fa-earth-americas mb-2" />

                <h4>Global Network</h4>
              </a>
            </li>
            {/* End Tab 6 Nav */}
          </ul>
          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">
              <div className="row gy-4">
                <div
                  className="col-lg-8 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Mission</h3>
                  <p>
                    At Activated Carbon, we strive to utilize the latest
                    technologies and innovations to safeguard our planet
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Increase efficiency of filtration and benefit from
                      maximised performance
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Purifies air of
                      pollutants, allergens, viruses & other impurities in an
                      effective way while maintaining hygiene standards
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Eco friendly design with potential to be reused over long
                      periods of time without significant steps taken for
                      regeneration/activating purposes
                    </li>
                  </ul>
                  <p>
                    Highly cost competitive when compared to the alternative
                    solutions available
                  </p>
                </div>
                <div
                  className="col-lg-4 order-1 order-lg-2 text-center panel-img"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img src={feature1} alt className="img-fluid" />
                </div>
              </div>
            </div>
            {/* End Tab Content 1 */}
            <div className="tab-pane" id="tab-2">
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Our Vision</h3>
                  <p>
                    Our vision is to become the global leader in understanding
                    and meeting the needs of customers in a rapidly changing
                    world.
                  </p>

                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      We aspire to be the go-to company for innovative solutions
                      that anticipate and respond to the evolving demands of our
                      customers across a broad range of industries and
                      geographies.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> We are committed
                      to investing in cutting-edge technologies and fostering a
                      culture of innovation that enables us to stay ahead of the
                      curve.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Our focus on
                      building strong, lasting relationships with our customers,
                      partners, and employees will continue to drive our
                      success, as we strive to create a better future for all.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center  panel-img">
                  <img src={feature2} alt className="img-fluid" />
                </div>
              </div>
            </div>
            {/* End Tab Content 2 */}
            <div className="tab-pane" id="tab-3">
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1 text-justify">
                  <h3>Our Specialization</h3>
                  <p>
                    Our clients keep coming back to us because Activated Carbon is
                    adept at providing high-grade Coconut shell based activated
                    carbon across the globe GRANULAR ACTIVATED CARBON Austro
                    Carbon, the foremost manufacturer of granular activated
                    carbon, provides extensive analytical support such as
                    feasibility studies and cost evaluations, as well as
                    complete system design, service, and troubleshooting to
                    accompany its products. With unparalleled proficiency in GAC
                    development, Activated Carbon offers tailored solutions that
                    cater to any GAC requirement you may have.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Activated Carbon has a diverse range of Powdered Activated
                      Carbon (PAC) products, each meticulously designed to
                      possess unique pore structures and adsorption properties.
                      The PAC products manufactured by Activated Carbon have
                      exceptional adsorptive capabilities that make them ideal
                      for eliminating various impurities from water, air,
                      liquids, and gases.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Activated Carbon has
                      extensive experience in producing customized powdered
                      activated carbons that cater to the specific requirements
                      of diverse industrial applications. Our PAC products are
                      engineered to meet the demands of a wide range of
                      industries, including municipal water treatment,
                      pharmaceutical product purification, food and beverage
                      decolorization, energy storage, and many more. With Austro
                      Carbon, you can expect a wide array of tailored PAC
                      solutions that effectively address your needs.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Pellets, which
                      are cylinder-shaped activated carbon, offer a multitude of
                      applications, such as eliminating volatile organic
                      compounds and mercury from natural gas and controlling
                      unpleasant odors. In Activated Carbon, Pellets are engineered
                      to possess a unique pore structure and adsorption
                      properties that enable them to effectively remove diverse
                      impurities from air and gas streams. In addition, pellets
                      are an environmentally-friendly product that can be
                      regenerated through thermal oxidation and reused several
                      times for the same purpose.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center  panel-img">
                  <img src={feature3} alt className="img-fluid " />
                </div>
              </div>
            </div>
            {/* End Tab Content 3 */}
            <div className="tab-pane" id="tab-4">
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Product Portfolio & Offering</h3>

                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Virgin Activated Carbon
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Pellet Activated Carbon
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Granular Activated Carbon
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      CTC value ranging from 50% to 70%
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Powdered Activated Carbon
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Iodine Value 400- 1500
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Acid/Water washed Activated Carbon and Wide range of mesh
                      sizes
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center  panel-img">
                  <img src={feature4} alt className="img-fluid" />
                </div>
              </div>
            </div>
            {/* End Tab Content 4 */}
            <div className="tab-pane" id="tab-5">
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Packing & Loading</h3>

                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      Activated Carbon's objective is to guarantee secure and swift
                      transportation.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      The packing of Activated Carbon will be carried out in
                      high-quality Polypropylene or HDPE bags weighing 25 kg,
                      500 kg, or 550 kg.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> These bags are
                      equipped with an inner liner and are shrink-wrapped to
                      provide protection against moisture during transit.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center  panel-img">
                  <img src={feature5} alt className="img-fluid" />
                </div>
              </div>
            </div>
            {/* End Tab Content 5 */}
            <div className="tab-pane" id="tab-6">
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Global Network</h3>

                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Our mission is
                      to drive innovation and create value by connecting leading
                      global customers
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center  panel-img">
                  <img src={feature6} alt className="img-fluid" />
                </div>
              </div>
            </div>
            {/* End Tab Content 6 */}
          </div>
        </div>
      </section>
      {/* End Features Section */}
    </div>
  );
};

export default About;
