import React, { useEffect ,useState} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Routes
import Header from "./components/header/Header";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Hero from "./components/hero/Hero";
import About from "./components/about/About";
import Service from "./components/service/Service";
import AOS from "aos";
import "aos/dist/aos.css";
import Industrial from "./components/industrialsector/Industrial";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import SocialMediaSideBar from "./components/SocialMediaSideBar/SocialMediaSideBar";
import Preloader from "./components/preloader/Preloader";
const App = () => {
  const [isLoading,setIsLoading]=useState(true);
  useEffect(() => {
    AOS.init();
    setTimeout(()=>{
      setIsLoading(false);
    },3000)
  }, []);

  return (
    <>
    {isLoading ? (
        
        <Preloader />
      ) : (
        <Router>
      <div className="App">
        <Header />
        <SocialMediaSideBar/>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/industrial" element={<Industrial />} />
          
          <Route path="/contact" element={<Contact />} />
        </Routes>
      
        <Footer />
      </div>
    </Router>
      )}
    
    </>
    
  );
};

export default App;




