import React from "react";
import "./contact.css";
const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-header">
          <h2 className="sectionheading">Contact Us</h2>
        </div>
      </div>

      <div className="container">
        <div className="row gy-5 gx-lg-5">
          <div className="col-lg-8 mx-auto">
            <div className="info">
              <h3>Get in touch</h3>
              <p>The Activated Carbon</p>
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0" />

                <div>
                  <h4>Location:</h4>
                  <p>B2-200 Preston Pkwy,Cambridge,ONN3H 5N1,Canada</p>
                </div>
              </div>
              {/* End Info Item */}
              {/* <div className="info-item d-flex">
              <i className="bi bi-envelope flex-shrink-0" />
              <div>
                <h4>Email:</h4>
                <p>info@example.com</p>
              </div>
            </div>End Info Item */}
              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0" />
                <div>
                  <h4>Call:</h4>
                  <p>
                    {" "}
                    +1(416)993-2955
                    <br />
                  </p>
                </div>
              </div>
              {/* End Info Item */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
