import React from 'react'
import "./service.css";
import carbon1 from "../../assests/service/carbon1.jpg";
import carbon2 from "../../assests/service/carbon2.jpg";
import carbon3 from "../../assests/service/carbon3.jpg";
import carbon4 from "../../assests/service/carbon4.jpg";
import carbon5 from "../../assests/service/carbon5.jpg";
import carbon6 from "../../assests/service/carbon6.jpg";


const Service = () => {
  return (
   <div id='productsession'>
    {/* ======= Services Section ======= */}
<section id="service" className="services">
  <div className="container" data-aos="fade-up">
    <div className="sectionheading">
      <h2>Product Characteristics</h2>
      <p></p>
    </div>
    <div className="row gy-5">
      <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item">
          <div className="img">
            <img src={carbon1} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Powdered Activated Carbon (PAC) </h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
      <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item">
          <div className="img">
            <img src={carbon2} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Granular Activated Carbon (GAC)</h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
      <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item">
          <div className="img">
            <img src={carbon3} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Pelletized Activated Carbon</h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
      <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item">
          <div className="img">
            <img src={carbon4} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Extruded Activated Carbon</h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
      </div>
{/* second row */}
     
     <div className='row gy-5 mt-5'>
    <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item service-item2">
          <div className="img">
            <img src={carbon5} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Carbon Cloth or Fiber</h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
      <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item">
          <div className="img">
            <img src={carbon6} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Carbon Block Filters</h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
      <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
        <div className="service-item">
          <div className="img">
            <img src={carbon1} className="img-fluid" alt />
          </div>
          <div className="details position-relative">
            <div className="icon">
              <i className="bi bi-activity" />
            </div>
            <a href="#" className="stretched-link">
              <h3>Carbon Nanotubes (CNTs)</h3>
            </a>
          </div>
        </div>
      </div>{/* End Service Item */}
    </div>
  </div>
</section>{/* End Services Section */}

   </div>
  )
}

export default Service