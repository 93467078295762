import React from 'react'
import "./hero.css";
import About from '../about/About';
import heroimg from "../../assests/img/heroimg.png"
import Service from '../service/Service';
import Industrial from '../industrialsector/Industrial';
import Contact from '../contact/Contact';
export default function Hero() {
  return (
    <>
    <div className="container-fluid " id="homepage">
      <section id="hero-animated " className="hero-animated ">
        <div
          className=" d-flex flex-column justify-content-center align-items-center text-center position-relative"
          data-aos="zoom-out"
        >
          <img src={heroimg} className="img-fluid animated" />
          <h2>Activated Carbon</h2>
          <p>Innovation in Green Revolution</p>
          <div className="d-flex">
            <a href="#productsession" className="btn-get-started scrollto">
              {" "}
              View all Products
            </a>
          </div>
        </div>
      </section>
     
    </div>
    <About/>
    <Service/>
    <Industrial/>
    <Contact/>
    </>
  );
}
