import React from "react";
import "./about.css";
import about4 from "../../assests/about/about.jpg";
import AboutSlider from "../swiper/AbourSlider";

export const Mission = () => {
  return (
    <>
      <div className="aboutus mt-5">
        <h2 className="sectionheading">About Us</h2>
        <section id="cta" className="cta">
          <div className="container " data-aos="zoom-out">
            <div className="row g-5">
              <div className="col-lg-6 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                <h3>
                  Who <em>We Are </em>{" "}
                </h3>
                <p className="text-left">
                  <span>
                    {" "}
                    <i className="bi bi-check-circle p-3" />
                  </span>
                  The Activated Carbon is an organisation established to provide
                  comprehensive and efficient carbon filter services. We
                  specialize in the design, manufacture, supply and installation
                  of activated charcoal filters for a variety of applications.
                  Our team consists of experienced professionals who have been
                  working with air filtration technologies for years.
                </p>
                <p className="text-left">
                  <span>
                    {" "}
                    <i className="bi bi-check-circle p-3" />
                  </span>
                  A activated carbon solution that is designed to improve air
                  quality -Providing a comprehensive range of products and
                  services for the purification of indoor air -Highly
                  experienced team with extensive knowledge in the field of air
                  filtration and purification -State of the art technology used
                  to ensure maximum efficiency and effectiveness -Committed to
                  providing customers with superior service, quality products,
                  competitive prices, fast delivery times and excellent customer
                  support
                </p>
                <p className="text-left">
                  <span>
                    {" "}
                    <i className="bi bi-check-circle p-3" />
                  </span>
                  With our extensive knowledge base and technical capabilities,
                  we are confident in providing quality service that satisfies
                  customer requirements at all times. For added convenience, we
                  offer solutions as well as tailored maintenance programs
                  designed to meet specific needs. Feel free to contact us
                  anytime if you need assistance with selecting or servicing
                  your activated carbon filter system!
                </p>
                {/* <a className="cta-btn align-self-start" href="#">
                Call To Action
              </a> */}
              </div>
              <div className="col-lg-6 col-md-6 order-first order-md-last d-flex align-items-center">
                <AboutSlider/>
                {/* <div className="img"> */}
                  {/* <img src={about4} alt className="img-fluid" /> */}
                 
                {/* </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* our mission */}
        <section id="onfocus" className="onfocus">
          <div className="container-fluid p-0" data-aos="fade-up">
            <div className="row g-0">
              <div className="col-lg-6 video-play position-relative">
                {/* <a href="#" className="glightbox play-btn" /> */}
              </div>
              <div className="col-lg-6">
                <div className="content d-flex flex-column justify-content-center h-100">
                  <h3>Research and Development</h3>
                  <p className="fst-italic">
                    Activated Carbon considers Research and Development as a
                    crucial component of their growth strategy, providing them
                    with a competitive edge in the market by introducing
                    innovative products. The company is dedicated to enhancing
                    product quality and creating new and advanced products. The
                    team at Activated Carbon comprises experienced professionals
                    with decades of experience in the carbon industry.
                  </p>
                  <p className="fst-italic">
                    Our foundation is built on innovation. Austro Carbon is
                    dedicated to achieving the objective it set over ten years
                    ago: to assist clients in fulfilling their most challenging
                    requirements for purification, separation, and
                    concentration. Austro Carbon adheres to the globally
                    recognized quality standards set by
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle" /> ASTM - (American
                      Society for Testing and Materials)
                    </li>
                    <li>
                      <i className="bi bi-check-circle" />
                      AARL - (Anglo American Research Laboratory)
                    </li>
                    <li>
                      <i className="bi bi-check-circle" /> BIS (Bureau Indian
                      Standards)
                    </li>
                    <li>
                      <i className="bi bi-check-circle" /> EU (European
                      Standards)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
