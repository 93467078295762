import React from 'react'
import './industrial.css';
import water from "../../assests/industry/water.jpg";
import environment from "../../assests/industry/environment.jpg";
import air from  "../../assests/industry/air.jpg";
import mercury from "../../assests/industry/mercury.jpg";
import medical from "../../assests/industry/medical.jpg";
import energy from "../../assests/industry/energy.jpg";
import industry from "../../assests/industry/industry.jpg";
import metals from "../../assests/industry/metals.jpg";

const Industrial = () => {
  return (
<>
<section id="portfolio" className="portfolio" data-aos="fade-up">
  <div className="container">
    <div className="sectionheading">
      <h2>Industrial Sectors</h2>
      <h4>We Cater to</h4>
    </div>
  </div>
  <div className="container-fluid" data-aos="fade-up" data-aos-delay={200}>
    <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">
   
      <div className="row g-0 portfolio-container">
        <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={water} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Municipal water Treatment</h4>
            <ul>
              <li>Drinking Water </li>
              <li>Waste water</li>
             <li>Water Reuse</li>
            </ul>
           
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={environment} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Environmental water Treatment</h4>
            <ul>
              <li>Remediation Water Treatment </li>
              <li>Industrial Wastewater</li>
             <li>Landfill Leachate</li>
            </ul>
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={air} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Environmental Air Treatment</h4>
          <ul>
            <li>Industrial-Air Treatment</li>
            <li>Remediation-Air Treatment</li>
            <li>Organic odors</li>
          </ul>
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={mercury} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Mercury Removal</h4>
           <ul>
            <li>Separation and Removal of mercury</li>
           </ul>
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={medical} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Medical/Pharmaceutical</h4>
            <ul>
            <li>Wound Care</li>
            <li>Hemo-Dialysis</li>
            <li>Pharmaceutical</li>
           </ul>
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={energy} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Energy Storage</h4>
            <ul>
              <li>Ultra Capacitors</li>
              <li>Advanced Batteries</li>
            </ul>
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={industry} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Industrial Process</h4>
           <ul>
            <li>Liquid  Chemical</li>
            <li>Catalyst Support </li>
            <li>Gas Processing</li>
            <li>Water Processing</li>
            <li>Ultrapure Water</li>
            <li>Bio-based Chemicals</li>
           </ul>
          </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
          <img src={metals} className="img-fluid" alt />
          <div className="portfolio-info">
            <h4>Metals Recovery</h4>
           <ul>
            <li>Gold</li>
            <li>Precious Metals</li>
            <li>Other Metals</li>
           </ul>
          </div>
   </div>
      </div>
    </div>
  </div>
</section>{/* End Portfolio Section */}

</>
  )
}

export default Industrial